<template>
  <div class="c-p-container">
    <div class="page-outter">
      <div align="center">
        <span class="c-p-title">九医药学院</span>
        <span class="c-p-description">药品全生命周期数字化运营平台，为全产业链的信息数字化管理提供解决方案</span>
        <div class="c-p-d1">
          <div class="c-p-d1-1">
            <div
              class="c-p-d1-1-1"
              :style="tdActive === index ? 'background: #6aa5ff' : ''"
              v-for="(item,index) in tds"
              :key="index"
              @click="handlerChange(index)"
            >
              <div class="d1">
                <img
                  class="m1"
                  v-lazy="item.icon"
                />
              </div>
              <span class="s1">{{item.name}}</span>
            </div>
          </div>
          <div class="c-p-d1-2">
            <ul>
              <li
                v-for="(item,index) in pros"
                :key="index"
              >
                <div class="c-p-2-item">
                  <div class="c-p-2-item-d1">
                    <img v-lazy="item.icon" />
                  </div>
                </div>
                <div class="c-p-2-s1">{{item.name}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const arr1 = [
  {
    icon: require("../../../assets/images/1-1.png"),
    name: "九药云零售药店管理系统",
  },
  {
    icon: require("../../../assets/images/1-2.png"),
    name: "智能医药仓储管理平台",
  },
  {
    icon: require("../../../assets/images/1-3.png"),
    name: "智慧药厂数据管理平台",
  },
  {
    icon: require("../../../assets/images/1-4.png"),
    name: "首营电子资料交换平台",
  },
  {
    icon: require("../../../assets/images/1-5.png"),
    name: "药品溯源平台",
  },
];
const arr2 = [
  {
    icon: require("../../../assets/images/1-1.png"),
    name: "医药零售",
  },
  {
    icon: require("../../../assets/images/1-2.png"),
    name: "医药仓储管理",
  },
  {
    icon: require("../../../assets/images/1-6.png"),
    name: "医药生产管理",
  },
];
const arr3 = [
  {
    icon: require("../../../assets/images/1-5.png"),
    name: "药品溯源",
  },
  {
    icon: require("../../../assets/images/1-7.png"),
    name: "药理跟踪",
  },
  {
    icon: require("../../../assets/images/1-8.png"),
    name: "临床试验",
  },
  {
    icon: require("../../../assets/images/1-9.png"),
    name: "药品学术",
  },
];
export default {
  name: "CollegePharmacy",
  data() {
    return {
      tdActive: 0,
      tds: [
        {
          icon: require("../../../assets/images/pro1.png"),
          name: "产品链",
        },
        {
          icon: require("../../../assets/images/pro2.png"),
          name: "应用场景",
        },
        {
          icon: require("../../../assets/images/pro3.png"),
          name: "价值链",
        },
      ],
      pros: arr1,
    };
  },
  methods: {
    handlerChange(n) {
      this.tdActive = n;
      if (n === 0) {
        this.pros = arr1;
      } else if (n === 1) {
        this.pros = arr2;
      } else {
        this.pros = arr3;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.c-p-container {
  border-top: 1px solid #fff;
  width: 100%;
  padding-bottom: 2.4rem;
  background-image: url("../../../assets/images/bg4.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.c-p-title {
  display: block;
  font-size: 0.8rem;
  color: #fff;
  margin-top: 2.4rem;
  font-weight: 600;
}
.c-p-description {
  display: block;
  font-size: 0.4rem;
  color: #fff;
  margin-top: 0.7rem;
}
.c-p-d1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.6rem;
  .c-p-d1-1 {
    width: 5rem;
    .c-p-d1-1-1 {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 3.5rem;
      background: #616261;
      cursor: pointer;
      &:hover {
        background: #6aa5ff;
      }
      .s1 {
        margin-top: 0.2rem;
        display: block;
        font-size: 0.36rem;
        color: #dcdadd;
      }
      .d1 {
        width: 1.6rem;
        height: 1.6rem;
      }
      .m1 {
        width: 100%;
      }
    }
  }
  .c-p-d1-2 {
    width: 20rem;
    height: 10rem;
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      margin-top: -3rem;
      li {
        width: 3.8rem;
        float: left;
        margin-left: 2.4rem;
        margin-top: 3rem;
        .c-p-2-item {
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 2.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #dcdadd;
          .c-p-2-item-d1 {
            width: 1.4rem;
            height: 1.4rem;
            // border: 1px dashed #868786;
            img {
              width: 100%;
            }
          }
        }
        .c-p-2-s1 {
          font-size: 0.38rem;
          color: #dcdadd;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>